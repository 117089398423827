import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import "./ArticleBlog.css";
import { getArticle } from "../../api/articlesApi";
import { Article } from "../../interfaces/interfaces";
import { formatDateForWeb } from "../../utils/dateUtils";

const FAQ: React.FC = () => {
  const { id, title } = useParams<{ id: string; title: string }>();
  const articleId = id ? parseInt(id, 10) : NaN;
  const [article, setArticle] = useState<Article | null>(null);

  useEffect(() => {
    if (articleId) {
      const fetchArticle = async () => {
        const headers = {
          "Content-Type": "application/json",
        };
        const fetchedArticle = await getArticle(articleId, headers); // Appelle la fonction pour récupérer l'article
        if (fetchedArticle) {
          setArticle(fetchedArticle); // Met à jour l'état avec l'article récupéré
        }
      };
      fetchArticle();
    }
  }, [articleId]);
  if (!article) {
    return <div>Article not found</div>;
  }
  const generateTOC = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const headers = doc.querySelectorAll("h2");
    const toc = Array.from(headers).map((header, index) => {
      const id = `section-${index}`;
      header.id = id;
      return (
        <li key={id}>
          <a href={`#${id}`}>{header.textContent}</a>
        </li>
      );
    });
    article.texte = doc.body.innerHTML; // update texteComplet with IDs
    return toc;
  };

  const tocItems = generateTOC(article.texte);

  return (
    <section id="ArticleBlog" className="bgDark">
      <div className="container py-5">
        <div className="row bgLight colorLight rounded my-5 py-3">
          <p className="text-center text-muted">
            {article.tags.map((tag, index) => (
              <small key={index} className="text-muted me-2">
                <FontAwesomeIcon icon={faTag} /> {tag.nom}
              </small>
            ))}
          </p>
          <h1 className="text-center section-title fs-1">{article.title}</h1>
          <p className="text-center text-muted">
            Publié le : {formatDateForWeb(article.date)}
          </p>
          <p className="text-center">{article.intro}</p>
          <img
            src={article.image}
            alt="Blog illustration"
            className="img-fluid rounded"
          />
          <div className="row">
            <div className="col-12 col-lg-4 order-1 sticky-toc-container">
              <div className="sticky-toc">
                <h2 className="mt-5">Sommaire</h2>
                <ul>{tocItems}</ul>
              </div>
            </div>
            <div className="col-12 col-lg-8 order-2">
              <div
                className="mt-5 texteArticleBlog"
                dangerouslySetInnerHTML={{ __html: article.texte }}
              />
              <div className="mt-2 text-muted small text-end">
                Article rédigé par {article.createdBy}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
