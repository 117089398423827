import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate 
} from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { NavbarAdmin } from "./components/NavbarAdmin";
import { ScrollToTopButton } from "./components/ScrollToTopButton";
import { ScrollToTop } from "./components/ScrollToTop";
import Home from "./pages/Home";
import PageFAQ from "./pages/PageFAQ";
import PageRealisations from "./pages/PageRealisations";
import PageContact from "./pages/PageContact";
import PageBlog from "./pages/PageBlog";
import PageArticleBlog from "./pages/PageArticleBlog";
import PageConfidentialite from "./pages/PageConfidentialite";
import PageCGU from "./pages/PageCGU";
import PageMentionsLegales from "./pages/PageMentionsLegales";
import PageServiceDetail from "./pages/PageServiceDetail";
import PageTeamComposition from "./pages/PageTeamComposition";
import PageNouvelArticle from "./pages/Admin/PageNouvelArticle";
import PageListeArticles from "./pages/Admin/PageListeArticles";
import PageModifierArticle from "./pages/Admin/PageModifierArticle";
import PrivateRoute from "./PrivateRoute";
import Login from "./components/Login/LoginPage";
import RegisterPage from "./components/Register/RegisterPage";

const App: React.FC = () => {
  return (
    <Router>
      {" "}
      {/* Le Router ici entoure tous les composants */}
      <AppContent />
    </Router>
  );
};

// Composant AppContent avec useLocation
const AppContent: React.FC = () => {
  const location = useLocation(); // Utilisation du hook ici

  // Vérification de la route admin
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isAuthRoute = location.pathname === "/admin/login" || location.pathname === "/admin/register";

  return (
    <div>
      {/* Affichage de la Navbar selon le chemin */}
      {!isAuthRoute && (isAdminRoute ? <NavbarAdmin /> : <Navbar />)}


      {!isAuthRoute && <ScrollToTopButton />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<PageFAQ />} />
        <Route path="/contact" element={<PageContact />} />
        <Route path="/realisations" element={<PageRealisations />} />
        <Route path="/blog" element={<PageBlog />} />
        <Route path="/articleBlog/:id/:title" element={<PageArticleBlog />} />
        <Route path="/PolitiqueDeConfidentialite" element={<PageConfidentialite />} />
        <Route path="/CGU" element={<PageCGU />} />
        <Route path="/MentionsLegales" element={<PageMentionsLegales />} />
        <Route path="/ServiceDetail/:title" element={<PageServiceDetail />} />
        <Route path="/equipe" element={<PageTeamComposition />} />

        {/* Routes d'administration */}
        <Route
          path="/admin/nouvelArticle"
          element={
            <PrivateRoute>
              <PageNouvelArticle />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/listeArticles"
          element={
            <PrivateRoute>
              <PageListeArticles />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/modifierArticle/:id"
          element={
            <PrivateRoute>
              <PageModifierArticle />
            </PrivateRoute>
          }
        />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/register" element={<RegisterPage />} />

        {/* Route catch-all pour les routes inexistantes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <ScrollToTopButton />
    </div>
  );
};

export default App;
