import React, { useState } from 'react';
import ServiceCard from './ServiceCard';

const Services: React.FC = () => {

  const servicesData = [
    {
      title: "Création de Sites Web",
      list: [
        "Design, conception et développement",
        "Expérience utilisateur optimisée",
        "SEO pour une meilleure visibilité"
      ],
      numero: "01",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png",
      redirect: "creation-sites-web"
    },
    {
      title: "E-commerce",
      list: [
        "Création de boutiques en ligne",
        "Gestion des paiements et des commandes"
      ],
      numero: "02",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png",
      redirect: "e-commerce"
    },
    {
      title: "Développement d'Applications",
      list: [
        "Mobile et web sur mesure",
        "Fonctionnalités avancées",
        "Intégration d'API"
      ],
      numero: "03",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png",
      redirect: "developpement-applications"
    },

  ];
  return (
    <section id="Services" className={`py-5 bgLight colorLight`}>
      <div className="container">
        <h2 className="text-center title-decoration section-title py-4">Nos services</h2>
        <p className="text-center mb-5 lead">
          <b>Bienvenue chez Good<span className="text-hightlight">Shi</span> ! Nous sommes là pour rendre votre présence en ligne aussi simple et efficace que possible. Découvrez nos services ci-dessous pour voir comment nous pouvons vous aider à atteindre vos objectifs numériques.</b>
        </p>
        <div className="row row-cols-1 row-cols-lg-3 g-lg-6">
          {servicesData.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              list={service.list}
              numero={service.numero}
              image={service.image}
              hoverImage={service.hoverImage}
              redirect={service.redirect}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
