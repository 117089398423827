import React from "react";


const TeamComposition: React.FC = () => {


    return (
        <section id="ServiceDetail" className="bgLight">
            <div className="container py-5">
                <div className="row bgLight colorLight">
                    <h1 className="text-center title-decoration section-title py-4">Notre équipe</h1>
                </div>
                <div className="row mt-5 bgLight colorLight">
                    <div className="col-12 col-md-4 mb-4">
                        <div className="p-2 colorLightEncapsuled rounded">
                            <div className="mb-3">
                                <img
                                    src="images/JulienAlfandari.jpg"
                                    alt="Julien Alfandari"
                                    className="img-fluid rounded"
                                />
                            </div>
                            <div className="text-center">
                                <h2 className="text-hightlight">Julien Alfandari</h2>
                                <p className="fw-bold">Front-end expert</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <h3 className="text-hightlight">Mon expérience</h3>
                        <p>
                            J'ai fait mes études à Lyon, où j'ai étudié les mathématiques et l'informatique. Cette formation m'a permis de développer mon esprit logique et d'aiguiser ma curiosité pour la résolution de problèmes. En parallèle, j'ai suivi une formation en psychologie, qui m'aide aujourd'hui à mieux comprendre les besoins de mes clients.
                            J'ai ensuite travaillé plusieurs années au sein d'une même entreprise, où j'ai acquis autonomie et rigueur, des qualités essentielles pour avancer dans le monde du développement. J'ai eu l'opportunité de collaborer avec de grands groupes tels que le PMU ou L'Équipe, en développant des API et des applications adaptées à leurs besoins.
                        </p>

                        <h3 className="text-hightlight">Mes compétences</h3>
                        <p>Grâce à mes expériences passées, je me suis spécialisé dans le développement d'applications web et les technologies qui l'entourent. Après avoir longtemps travaillé seul, je ressens aujourd'hui le besoin de partager mes connaissances et de continuer à apprendre avec et grâce à GoodShi. Je développe principalement en PHP pour le côté back-end et avec React pour le côté front-end.</p>
                        <h3 className="text-hightlight">En quelques mots</h3>
                        <p>L'envie d'entreprendre s'est rapidement imposée à moi, mais j'ai pris le temps de monter en compétences avant de me lancer dans cette aventure. Aujourd'hui, j'imagine GoodShi comme une entreprise ayant pour mission d'aider les autres à concrétiser leurs projets. Ma vision du développement informatique est avant tout humaine : il s'agit de se mettre au service des femmes et des hommes pour les accompagner, les épauler et les libérer des tâches répétitives et contraignantes.</p>
                        <b>Une idée, un projet, un besoin ? Contactez-nous, et nous ferons notre maximum pour vous accompagner dans sa réalisation.</b>
                    </div>
                </div>
                <hr></hr>
                <div className="row mt-5 bgLight colorLight">
                    <div className="col-12 col-md-8">
                        <h3 className="text-hightlight">Mon expérience</h3>
                        <p>J’ai débuté chez IBM, où j’ai appris à optimiser les performances des applications et à résoudre des problématiques complexes dans un environnement Agile. Chez Softeam, j’ai eu l’opportunité de travailler avec une variété de clients sur plusieurs projets différents, ce qui m’a permis de développer une grande capacité d’adaptation. Enfin, chez Alkante, en plus du développement, j'ai été en contact direct avec l'utilisateur final pour répondre à ses interrogations, ce qui m'a permis de renforcer mes compétences en communication.<br></br>
                        Ces expériences variées m'ont permis de développer une polyvalence précieuse et d'acquérir une vision globale des différents aspects du développement et de la gestion de projets. Chaque entreprise m'a offert des défis uniques, me permettant de renforcer mes compétences techniques et personelles. Cette diversité m'a permis de m'enrichir professionnellement, de m'adapter rapidement à de nouveaux environnements et de proposer des solutions innovantes.</p>
                        <h3 className="text-hightlight">Mes compétences</h3>
                        <p>Avec plusieurs années d'expérience en développement backend, je me suis spécialisé dans l'écosystème Java et Spring. Mon approche se concentre sur l'optimisation continue des performances et la garantie de la fiabilité des systèmes, afin de répondre aux besoins évolutifs des applications modernes.</p>
                        <h3 className="text-hightlight">En quelques mots</h3>
                        <p>Passionné par les échecs, la lecture et le piano, j’aime autant la réflexion stratégique que l’exploration d’univers riches et inspirants. Les jeux vidéo occupent aussi une place importante dans mes loisirs, combinant défi et immersion. Amoureux des grands espaces et de la sérénité nordique, la Norvège fait partie de mes destinations de rêve. Je me laisse guider par une philosophie qui allie prudence et réflexion : "Ne fais jamais ce que tu ne peux défaire avant d'avoir réfléchi à ce que tu ne pourras plus faire une fois que tu l'auras fait."</p>
                    </div>
                    <div className="col-12 col-md-4 mt-4">
                        <div className="p-2 colorLightEncapsuled rounded">
                            <div className="mb-3">
                                <img
                                    src="images/JulienDegruson.png"
                                    alt="Julien Degruson"
                                    className="img-fluid rounded"
                                />
                            </div>
                            <div className="text-center">
                                <h2 className="text-hightlight">Julien Degruson</h2>
                                <p className="fw-bold">Back-end expert</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamComposition;
