import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const location = useLocation();
  return (
    <nav id="navbar" className={`navbar sticky-top navbar-expand-lg navbar-dark bgDark colorDark no-select`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/images/GoodShi.png" alt="Logo" width="50" height="50" className="d-inline-block align-top" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Accueil</Link>
            </li>
            {<li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/realisations' ? 'active' : ''}`} to="/realisations">Nos réalisations</Link>
            </li>}
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/blog' ? 'active' : ''}`} to="/blog">Notre blog</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact">Nous contacter</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/equipe' ? 'active' : ''}`} to="/equipe">Notre équipe</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/FAQ' ? 'active' : ''}`} to="/FAQ">FAQ</Link>
            </li>
          </ul>
          {/*<form className="d-flex ms-lg-3">
            <input className="form-control me-2 input-search" type="search" placeholder="Recherche" aria-label="Search" />
          </form>*/}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
