// src/pages/Blog.tsx
import React from 'react';
import { TeamComposition } from '../components/TeamComposition';
import { Footer } from '../components/Footer';
const PageTeamComposition: React.FC = () => {
    return (
        <>
            <TeamComposition />
            <Footer backgroundColor="bgDark colorDark" />
        </>
    );
};

export default PageTeamComposition;