import React from 'react';

const ContactReminder: React.FC = () => {
    return (
        <section id="ContactReminder" className={`py-5 bgLight colorLight`}>
            <div className="container">
                <p className="text-center mb-5 lead">
                    <a href="/contact" className="text-3d">Une question ? Un projet en tête ? Contactez-nous, nous serons ravis d'échanger avec vous !</a>
                </p>
            </div>
        </section>
    );
};

export default ContactReminder;
