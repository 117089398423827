// src/pages/Blog.tsx
import React from 'react';
import ServiceDetail from '../components/Services/ServiceDetail/ServiceDetail';
import { Footer } from '../components/Footer';
const PageServiceDetail: React.FC = () => {
    return (
      <>
        <ServiceDetail />
        <Footer backgroundColor="bgDark colorDark" />
      </>
    );
  };
  
  export default PageServiceDetail;