import React from "react";
import { useParams } from "react-router-dom";

const services = [
    {
        title: "creation-sites-web",
        displayedTitle: "Création de sites web",
        description: <div>
            <p className="mb-4">
                <b>La création d'un site web est un processus structuré visant à concevoir une plateforme professionnelle et performante,
                    adaptée à vos besoins. Voici les principales étapes de développement :</b>
            </p>
            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Les étapes de création d'un site web</span></h2>
            <h3>1. Analyse des besoins & cahier des charges (<i>1 à 3 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Définition des objectifs (<i>présentation, blog, site vitrine, etc.</i>).</li>
                <li>Identification du public cible.</li>
                <li>Choix des fonctionnalités essentielles (<i>formulaire de contact, réservation, etc.</i>).</li>
            </ul>

            <h3>2. Conception & maquette (<i>3 à 7 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Création d'un prototype visuel (<i>wireframe, maquette</i>).</li>
                <li>Validation du design et de l'expérience utilisateur (<i>UX/UI</i>).</li>
            </ul>

            <h3>3. Développement du site (<i>1 à 4 semaines</i>)</h3>
            <ul className="list-unstyled">
                <li>Intégration du design en HTML, CSS, et JavaScript.</li>
                <li>Développement des fonctionnalités (<i>CMS, espace membre, etc.</i>).</li>
                <li>Mise en place de l'optimisation pour le référencement (<i>SEO</i>).</li>
            </ul>

            <h3>4. Tests & corrections (<i>3 à 7 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Vérification du bon fonctionnement sur différents appareils et navigateurs.</li>
                <li>Correction des bugs et ajustements.</li>
                <li>Optimisation des performances et sécurité.</li>
            </ul>

            <h3>5. Mise en ligne & suivi (<i>1 à 3 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Installation sur l'hébergement et configuration du domaine.</li>
                <li>Suivi post-lancement et maintenance éventuelle.</li>
            </ul>

            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Temps et coût estimé</span></h2>
            <p>
                Le temps de développement varie selon la complexité du projet :
            </p>
            <ul className="list-unstyled">
                <li><strong>Site vitrine simple :</strong> 1 à 2 semaines (<i>à partir de 800€</i>).</li>
                <li><strong>Site sur mesure avec fonctionnalités avancées :</strong> 3 à 6 semaines (<i>à partir de 2000€</i>).</li>
                <li><strong>Site e-commerce :</strong> 4 à 8 semaines (<i>à partir de 3000€</i>).</li>
            </ul>
            <hr></hr>
            <p>
                <b>Il est important de noter que le tarif dépend des fonctionnalités spécifiques, du design et des services additionnels (<i>hébergement, SEO, maintenance</i>).</b>
            </p>
            <p><a href="/contact">Contactez-nous</a> pour un devis personnalisé !</p>
            </div>,
    },
    {
        title: "e-commerce",
        displayedTitle: "E-commerce",
        description: <div>
            <p className="mb-4">
                <b>Vous souhaitez vendre vos produits ou services en ligne ? La création d'une boutique e-commerce permet d'automatiser
                    les ventes, la gestion des commandes et des paiements, tout en offrant une expérience optimale à vos clients.
                    Voici les principales étapes du développement d'un site e-commerce :</b>
            </p>

            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Les étapes de création d'une boutique en ligne</span></h2>

            <h3>1. Analyse des besoins & cahier des charges (<i>2 à 5 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Définition du type de boutique (<i>produits physiques, numériques, services, abonnements</i>).</li>
                <li>Choix des fonctionnalités essentielles (<i>gestion des stocks, filtres de recherche, promotions, etc.</i>).</li>
                <li>Sélection des moyens de paiement et modes de livraison.</li>
            </ul>

            <h3>2. Conception & maquette (<i>5 à 10 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Création d'une maquette pour visualiser l'interface utilisateur.</li>
                <li>Conception de l'expérience utilisateur (<i>UX</i>) et design attractif (<i>UI</i>).</li>
            </ul>

            <h3>3. Développement du site e-commerce (<i>3 à 8 semaines</i>)</h3>
            <ul className="list-unstyled">
                <li>Développement du site avec un CMS (<i>Shopify, WooCommerce</i>) ou un site sur mesure (<i>React, Next.js, etc.</i>).</li>
                <li>Intégration d'un système de gestion des commandes et clients.</li>
                <li>Mise en place des paiements sécurisés (<i>Stripe, PayPal, Carte Bancaire, etc.</i>).</li>
                <li>Configuration des options de livraison et suivi des expéditions.</li>
            </ul>

            <h3>4. Tests & corrections (<i>5 à 10 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Tests des paiements et validation du processus de commande.</li>
                <li>Vérification de la compatibilité mobile et des performances du site.</li>
                <li>Correction des bugs et ajustements UX.</li>
            </ul>

            <h3>5. Mise en ligne & accompagnement (<i>2 à 5 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Installation sur l'hébergement et configuration du nom de domaine.</li>
                <li>Optimisation SEO pour améliorer le référencement Google.</li>
                <li>Formation rapide pour l'utilisation et la gestion de la boutique.</li>
            </ul>

            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Temps et coût estimé</span></h2>
            <p>
                Le temps de développement d'une boutique en ligne dépend du niveau de personnalisation et des fonctionnalités :
            </p>
            <ul className="list-unstyled">
                <li><strong>Petite boutique (<i>10-20 produits</i>) :</strong> 3 à 5 semaines (<i>à partir de 2000€</i>).</li>
                <li><strong>Boutique avec catalogue moyen (<i>50-200 produits</i>) :</strong> 5 à 8 semaines (<i>à partir de 4000€</i>).</li>
                <li><strong>Marketplace ou boutique sur mesure :</strong> 8 à 12 semaines (<i>à partir de 8000€</i>).</li>
            </ul>
            <hr></hr>
            <p>
                <b>Il est important de noter que le tarif dépend des fonctionnalités spécifiques (<i>gestion des abonnements, automatisation, multi-langues, etc.</i>),
                    de la plateforme choisie et des services additionnels (<i>SEO, maintenance, marketing digital</i>).</b>
            </p>
            <p><a href="/contact">Contactez-nous</a> pour un devis personnalisé !</p>
        </div>
        ,
    },
    {
        title: "developpement-applications",
        displayedTitle: "Développement d'applications",
        description: <div>
            <p>
                <b>Vous avez un projet d'application web ou mobile ? Que ce soit une plateforme en ligne, une application mobile native
                ou hybride, nous concevons des solutions performantes et adaptées à vos besoins.
                Voici les principales étapes du développement d'une application :</b>
            </p>

            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Les étapes du développement d'une application</span></h2>

            <h3>1. Analyse des besoins & conception du projet (<i>5 à 10 jours</i>)</h3>
            <ul className="list-unstyled">
                <li>Définition des fonctionnalités et des objectifs.</li>
                <li>Choix de la technologie : Web (<i>React, Next.js</i>), Mobile (<i>React Native, Flutter, Swift, Kotlin</i>).</li>
                <li>Création du cahier des charges et des user stories.</li>
            </ul>

            <h3>2. Conception UX/UI & prototypage (<i>1 à 3 semaines</i>)</h3>
            <ul className="list-unstyled">
                <li>Réalisation de wireframes et prototypes interactifs.</li>
                <li>Conception d'une interface moderne et ergonomique.</li>
            </ul>

            <h3>3. Développement de l'application (<i>4 à 12 semaines</i>)</h3>
            <ul className="list-unstyled">
                <li>Développement des fonctionnalités principales (<i>authentification, gestion des utilisateurs, API, base de données, etc.</i>).</li>
                <li>Intégration des services tiers (<i>paiement, notifications, cartes interactives, etc.</i>).</li>
                <li>Optimisation des performances et de la sécurité.</li>
            </ul>

            <h3>4. Tests, corrections & déploiement (<i>2 à 6 semaines</i>)</h3>
            <ul className="list-unstyled">
                <li>Tests sur différents appareils et navigateurs.</li>
                <li>Correction des bugs et amélioration de l'expérience utilisateur.</li>
                <li>Déploiement sur les stores (<i>App Store, Google Play</i>) ou en ligne.</li>
            </ul>

            <h3>5. Maintenance & évolutions</h3>
            <ul className="list-unstyled">
                <li>Support technique et corrections de bugs.</li>
                <li>Ajout de nouvelles fonctionnalités selon les retours utilisateurs.</li>
                <li>Suivi des performances et mises à jour régulières.</li>
            </ul>

            <h2 className="main-title-blogItem mb-4"><span className="pb-1">Temps et coût estimé</span></h2>
            <p>
                Le temps de développement varie selon la complexité de l'application :
            </p>
            <ul className="list-unstyled">
                <li><strong>Application simple (<i>ex: Todo-list, gestion de tâches</i>) :</strong> 4 à 6 semaines (<i>à partir de 3000€</i>).</li>
                <li><strong>Application métier avec base de données :</strong> 8 à 12 semaines (<i>à partir de 6000€</i>).</li>
                <li><strong>Application mobile avancée (<i>Marketplace, réseau social, SaaS</i>) :</strong> 12+ semaines (<i>à partir de 10 000€</i>).</li>
            </ul>
            <hr></hr>
            <p>
                <b>Il est important de noter que le tarif dépend des technologies utilisées, des fonctionnalités demandées et des services additionnels
                (<i>hébergement, maintenance, mises à jour, marketing digital</i>).</b>
            </p>
            <p><a href="/contact">Contactez-nous</a> pour un devis personnalisé !</p>
        </div>
        ,
    },
];

const ServiceDetail: React.FC = () => {
    const { title } = useParams<{ title: string }>();
    // Trouver l'enregistrement correspondant
    const service = services.find((s) => s.title === title);

    if (!service) {
        return <p>Service non trouvé.</p>;
    }
    return (
        <section id="ServiceDetail" className="bgLight">
            <div className="container py-5">
                <div className="row bgLight colorLight">
                    <h1 className="text-center title-decoration section-title py-4">{service.displayedTitle}</h1>
                    <div className="text-center">{service.description}</div>
                </div>
            </div>
        </section>
    );
};

export default ServiceDetail;
