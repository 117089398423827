import React, { useState, useEffect } from "react";
import BlogItem from "./BlogItem";
import { Link } from "react-router-dom";
import { Article } from "../../interfaces/interfaces";
import { getAllArticles } from "../../api/articlesApi";

const Blog: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const fetchedArticles = await getAllArticles(headers);
        setArticles(fetchedArticles);
      } catch (error) {
        console.error("Erreur lors du chargement des articles :", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (isLoading) {
    return (
      <section id="Blog" className="bgLight">
        <div className="container-fluid py-5 text-center">
          <h1 className="section-title">Chargement en cours...</h1>
        </div>
      </section>
    );
  }

  if (articles.length === 0) {
    return (
      <section id="Blog" className="bgLight">
        <div className="container-fluid py-5 text-center">
          <h1 className="section-title">Aucun article disponible.</h1>
        </div>
      </section>
    );
  }

  const firstBlogItem = articles[0];
  const remainingBlogItems = articles.slice(1);

  let rowCount = 0;
  let separationClass = "";

  return (
    <section id="Blog" className="bgLight">
      <div className="container-fluid py-5">
        <h1 className="text-center title-decoration section-title py-4 mb-5">
          Notre Blog
        </h1>
        <div className="row bgLight colorLight rounded my-5 py-5 text-center">
          <div className="col-md-1"></div>
          <div className="col-md-5 d-flex align-items-center">
            <div>
              <h2 className="mb-4 main-title-blogItem pb-4">
                <span className="pb-4 pe-5">{firstBlogItem.title}</span>
              </h2>
              <p className="m-0">{firstBlogItem.intro}</p>
              <Link
                to={`/articleBlog/${firstBlogItem.id}/${firstBlogItem.title}`}
                className="stretched-link"
              >
                <div className="learn-more-button text-center p-3 my-5 mb-md-0 mt-5">
                  LIRE L'ARTICLE
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <img
              src={firstBlogItem.image}
              alt="Blog illustration"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row my-2 g-4">
          <div className="d-none d-lg-block w-100 row-separation"></div>
          {remainingBlogItems.map((item, index) => {
            const isEvenElement = index % 2 === 0;
            const position = isEvenElement ? "even" : "odd";
            const isNewRow = index % 2 === 0;

            if (isNewRow) {
              rowCount++;
            }
            separationClass = isNewRow
              ? `col-2 col-separation bgDark ${
                  rowCount % 2 === 0
                    ? "col-separation-even"
                    : "col-separation-odd"
                }`
              : "";

            return (
              <React.Fragment key={index}>
                {isNewRow && (
                  <div className="d-none d-lg-block col-1 bgDark"></div>
                )}
                {isNewRow && index !== 0 && (
                  <div className="w-100 row-separation"></div>
                )}
                {isNewRow && index !== 0 && (
                  <div className="d-none d-lg-block col-1 bgDark"></div>
                )}
                <div className="col-md-5 col-lg-4 text-center bgDark colorDark">
                  <BlogItem
                    article={item}
                    link={`/articleBlog/${item.id}/${encodeURIComponent(
                      item.title
                    )}`}
                    position={position}
                  />
                </div>
                {isEvenElement && <div className={separationClass}></div>}
              </React.Fragment>
            );
          })}
          <div className="d-none d-lg-block col-1 bgDark"></div>
          <div className="w-100 row-separation"></div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
