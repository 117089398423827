import React, { useEffect, useState, useRef } from 'react';
import "./Realisations.css";

const Realisations: React.FC = () => {
    const realisationData = [
        { title: 'Province Courses', text: '<b>Un hub incontournable pour les passionnés de courses hippiques</b><br> Conçu pour Turfcom, Province Courses est une plateforme complète dédiée aux amateurs de trot en France. Le site centralise un large éventail de services : vidéos en live et en replay, statistiques détaillées issues d’une base de données enrichie en continu, et même une boutique en ligne avec gestion des comptes clients et facturation. Une solution clé en main pour les professionnels et passionnés du turf.', image: '/images/realisations/site_pc.jpg', link:'https://province-courses.fr/' },
        { title: 'Mickaël Cormy', text: '<b>Un site dynamique au service d’un entraîneur renommé</b><br>Mickaël Cormy, entraîneur de trotteurs français, souhaitait un site web lui permettant de suivre l’évolution de son écurie et de valoriser ses performances. Grâce à une base de données mise à jour automatiquement, le site affiche en temps réel les résultats de ses chevaux, sans intervention manuelle. Un outil efficace pour communiquer avec sa communauté.', image: '/images/realisations/site_cormy.jpg', link:'https://mickaelcormy.fr/' },
        { title: 'AETCE', text: '<b>Une vitrine interactive pour les ventes aux enchères de chevaux</b><br>L’Association des Éleveurs de Trotteurs du Centre Est organise régulièrement des ventes aux enchères. Leur site web leur permet de mettre en avant les résultats des ventes et le palmarès des chevaux vendus. Connecté à une base de données de performances et enrichi de nombreuses vidéos, il offre aux éleveurs et acheteurs un suivi précis des chevaux passés sur leur ring.', image: '/images/realisations/site_aetce.jpg', link:'https://vente-aetce.fr/' },
        { title: 'CCN', text: '<b>Un salon digital qui s’adapte aux nouvelles contraintes</b><br>Le Conseil des Chevaux Normandie organise chaque année un salon pour présenter des trotteurs. Face à la pandémie, l’événement a dû évoluer vers un format digital. Nous avons donc conçu un site modulable, mis à jour chaque année selon leurs besoins, pour garantir une continuité et offrir une vitrine interactive aux éleveurs et acheteurs.', image: '/images/realisations/site_salon.jpg', link:'https://www.salondutrotnormandie.fr/' },
        { title: 'Turfcom', text: '<b>Un site vitrine pour une agence de presse spécialisée</b><br>Turfcom, acteur majeur de la presse hippique, souhaitait un site reflétant son expertise et son savoir-faire. Nous avons conçu une vitrine claire et impactante, mettant en avant leur production éditoriale et leur expertise dans le domaine des courses.', image: '/images/realisations/site_turfcom.jpg', link:'https://turfcom.info/' },
        { title: 'Julien Soula', text: '<b>Un portfolio sur-mesure pour un graphiste talentueux</b><br>Julien Soula, graphiste, voulait un site vitrine élégant et facile à mettre à jour pour présenter ses créations. Après avoir conçu lui-même l’identité graphique, il nous a confié la réalisation technique pour un rendu fidèle à sa vision.', image: '/images/realisations/site_js.jpg', link:'https://www.juliensoula.fr/' },
    ];
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef<HTMLDivElement | null>(null);

    const getColsPerRow = () => {
        if (window.innerWidth < 768) return 1; // xs -> col-12
        if (window.innerWidth < 992) return 2; // md -> col-6
        return 3; // lg -> col-4
    };

    const [startTouch, setStartTouch] = useState(0);

    const handleTouchStart = (e: React.TouchEvent) => {
        console.log('toto');
        const touchStart = e.touches[0].clientX;
        setStartTouch(touchStart);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const touchMove = e.touches[0].clientX;
        const distance = startTouch - touchMove;

        // Si on fait un mouvement vers la gauche (distance > 0), on change l'index
        if (distance > 50) {
            setActiveIndex((prevIndex) => Math.min(realisationData.length - 1, prevIndex + 1));
            setStartTouch(touchMove); // Met à jour le point de départ pour éviter des changements multiples sur un même mouvement
        } 
        // Si on fait un mouvement vers la droite (distance < 0), on change l'index
        else if (distance < -50) {
            setActiveIndex((prevIndex) => Math.max(0, prevIndex - 1));
            setStartTouch(touchMove); // Met à jour le point de départ pour éviter des changements multiples sur un même mouvement
        }
    };

    const handleTouchEnd = () => {
        setStartTouch(0); // Réinitialise le point de départ
    };

    useEffect(() => {
        const updateTranslate = () => {
            if (!carouselRef.current) return;
            const colsPerRow = getColsPerRow();
            let translate;
            if(colsPerRow === 3){
                translate = -((activeIndex-1) * (100 / colsPerRow));
            }else if(colsPerRow === 2){
                translate = -((activeIndex) * (100 / colsPerRow)) + 1;
            }else{
                translate = -((activeIndex) * (100 / colsPerRow));
            }
            carouselRef.current.style.transform = `translateX(${translate}%)`;
        };

        updateTranslate();
        window.addEventListener("resize", updateTranslate);

        return () => window.removeEventListener("resize", updateTranslate);

    }, [activeIndex]);

    const handleRealisationClick = (index: number) => {
        setActiveIndex(index);
    };
    
    return (
        <section id="Realisations" className="bgLight colorLight">
            <>
                <div className="container py-5 wrapper">
                    <h1 className="text-center title-decoration section-title py-4">Nos réalisations</h1>
                    <div className="row inner mt-5"
                        ref={carouselRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        >
                        {realisationData.map((data, index) => (
                            <div
                                key={index}
                                className={`col-12 col-md-6 col-lg-4 realisation-container ${index === activeIndex ? 'active' : 'inactive'}`}
                                onClick={() => handleRealisationClick(index)}
                            >
                                <div className="realisation-card card h-100 text-center">
                                    <div className="image-realisation">
                                        <img src={data.image} className="card-img-top" alt="..." />
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title my-3">{data.title}</h3>
                                        <p className="card-text p-2" dangerouslySetInnerHTML={{ __html: data.text }}></p>
                                    </div>
                                    <div className="card-footer p-4 border-0">
                                        <a href={`${data.link}`} target="_blank"><div className="learn-more-button text-center p-3">EN SAVOIR PLUS</div></a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="map realisation-carousel-indicators py-5 d-flex justify-content-center">
                    {realisationData.map((_, index) => (
                        <div
                            key={index}
                            className={`indicator ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => handleRealisationClick(index)}
                        />
                    ))}
                </div>
            </>
        </section>
    );
};

export default Realisations;
